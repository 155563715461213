<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{ $store.state.language.lang[1] }}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[147]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="m_body_title">
              <h2>
                {{$store.state.language.lang[147]}}</h2>
            </div>

          <div class="maps_site">

            <div class="maps_link">
              <div class="row">

                <div class="col-xl-4 col-sm-6 col-12" >
                  <div class="footer_link_header">
                    <router-link to="/" >{{$store.state.language.lang[1]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/central/office" >{{$store.state.language.lang[9]}}</router-link>
                  </div>

                  <div class="footer_link">
                    <router-link to="/council" >{{$store.state.language.lang[12]}}</router-link>
                  </div>

                  <div class="footer_link">
                    <router-link to="/agency/management" >{{$store.state.language.lang[10]}}</router-link>
                  </div>


                  <div class="footer_link">
                    <router-link to="/vacancy" >{{$store.state.language.lang[19]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/">
                      <router-link to="/museum" >{{$store.state.language.lang[18]}}</router-link>
                    </router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/activity" >{{$store.state.language.lang[17]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/organization" >{{$store.state.language.lang[16]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/regulations" >{{$store.state.language.lang[15]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/resolutions" >{{$store.state.language.lang[14]}}</router-link>
                  </div>
                  <div class="footer_link">
                    <router-link to="/territorial" >{{$store.state.language.lang[13]}}</router-link>
                  </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                  <div class="maps_text">{{$store.state.language.lang[3]}}</div>
                  <div class="maps_links">
                    <router-link to="/news" >{{ $store.state.language.lang[69] }}</router-link>
                  </div>
                  <div class="maps_links">
                    <router-link to="/press_secretary" >{{ $store.state.language.lang[71] }}</router-link>
                  </div>
                  <div class="maps_links">
                    <router-link to="/press_releases" >{{ $store.state.language.lang[72] }}</router-link>
                  </div>
                  <div class="maps_links">
                    <router-link to="/tenders" >{{ $store.state.language.lang[74] }}</router-link>
                  </div>
                  <div class="maps_links">
                    <router-link to="/media" >{{ $store.state.language.lang[75] }}</router-link>
                  </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                  <div class="maps_text">{{$store.state.language.lang[2]}}</div>
                  <div class="maps_links">
                    <router-link to="/service">
                      {{$store.state.language.lang[2]}}
                    </router-link>
                  </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                  <div class="maps_text">{{$store.state.language.lang[6]}}</div>
                  <div class="maps_links">
                    <router-link to="/opendata">
                      {{$store.state.language.lang[6]}}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";

export default {
  components: {
    Footer,
    Navbar,

  },
  data() {
    return {

    }
  },
  methods: {
    open_modal() {

    }
  }
}
</script>
